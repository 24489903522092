import React, { useContext } from 'react'
import './CartItem.styles.css'
import { CartContext } from '../../../App';

const CartItem = (props) => {
    const { bookData } = props;
    const { cartItems, setCartItems } = useContext(CartContext);

    const handleRemove = () => {
        setCartItems(cartItems.filter((item) => item.id !== bookData.id));
    }
  return (
    <div className="cart-item">
            <div className="cart-item-img-container">
                <img src={bookData.book_url} alt="" className="cart-item-img"/>
            </div>

            <div className="cart-item-content-container">
                <h2>{bookData.book_name}</h2>
                <h3 className="cart-item-price">&#2547;{bookData.price}</h3>

                <button className='delete-btn' onClick={handleRemove}>Remove from Cart</button>
            </div>
        </div>
  )
}

export default CartItem
