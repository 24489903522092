import React from "react";
import "./ProductCard.styles.css";
import { Link } from "react-router-dom";

const ProductCard = (props) => {
  const { bookData } = props;
  return (
    <div className="product-cart">
      <div className="product-img-container">
        <img className="product-img" src={bookData.book_url} alt="" />
      </div>

      <div className="product-details-container">
        <h3>{bookData.book_name}</h3>
        <p className="price">&#2547;{bookData.price}</p>
      </div>
      <div className="buttons flex justify-between items-center">
        <button type="button" className="primary-btn">
          Add to Cart
        </button>

        {/* <Link to='/checkout' className="primary-btn">
          Order
        </Link> */}
        <Link to={`/book-details/${bookData.id}`} className="primary-btn">
          Details
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
