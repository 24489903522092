import React from "react";
import "./ProductList.styles.css";
import ProductCard from "../../cards/productCard/ProductCard";
import { BookData } from "../../../util/BookData";

const ProductList = () => {
  return (
    <section className="product-container">
      <div className="container">
        <h2>"Jinnopex Publication"<span> সম্পাদিত বই সমূহ</span></h2>
        <p>নিচের বইগুলো থেকে যেটি দরকার, সেটির উপরে <span style={{color: "red"}}>ক্লিক</span> করুন</p>

        <div className="list-container flex wrap justify-between items-center">
        {BookData.map((book) => {
            return (
                <ProductCard key={book.id} bookData={book} />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ProductList;
