import React from "react";
import "./Login.styles.css";
import Navbar from "../../components/layouts/navbar/Navbar";
import AuthForm from "../../components/form/authForm/AuthForm";

const Login = () => {
  return (
    <React.Fragment>
      <Navbar darkTheme={true} />

      <section className="signup-container flex justify-center items-center">
        <div className="content-wrapper">
          <h2>Login</h2>
          <p>Login with email and password.</p>

          <AuthForm btn_title="Login"/>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Login;
