import React from "react";
import "./BookDetails.styles.css";
import Navbar from "../../components/layouts/navbar/Navbar";
import Details from "../../components/layouts/details/Details";
import Footer from "../../components/layouts/footer/Footer";

const BookDetails = () => {
  return (
    <section>
      <Navbar darkTheme={true} />
      <Details />
      <Footer />
    </section>
  );
};

export default BookDetails;
