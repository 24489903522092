import React, { useContext } from "react";
import "./CartItemsContainer.styles.css";
import CartItem from "../../cards/cartItem/CartItem";
import { CartContext } from "../../../App";

const CartItemsContainer = () => {
  const { cartItems, totalAmount } = useContext(CartContext);

  return (
    <section className="cart-items-container">
      <div className="container">
        {totalAmount === 0 ? (
          <h2>Your cart is empty!</h2>
        ) : (
          <React.Fragment>
            <h2>Cart</h2>

            {cartItems.map((item) => (
              <CartItem key={item.id} bookData={item} />
            ))}

            <h2>Total Amount = {totalAmount}</h2>
            <button
              style={{
                backgroundColor: "var(--primary-color)",
                padding: ".5rem 1rem",
                marginTop: "1rem",
                color: "#fff",
                borderRadius: "5px",
              }}
            >
              Proceed to Checkout
            </button>
          </React.Fragment>
        )}
      </div>
    </section>
  );
};

export default CartItemsContainer;
