import React, { useRef } from "react";
import "./ContactForm.styles.css";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const form = useRef();
  const serviceId = "service_clfrjbx";
  const templateId = "template_mblurhz";
  const publicKey = "CCTqceLy9dMRYxjXY";

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(serviceId, templateId, form.current, publicKey)
    .then((response) => {
      console.log(response.text);
    })
    .catch((error) => {
      console.log(error.text);
    })

    e.target.reset();
  };

  return (
    <form onSubmit={handleSubmit} ref={form} className="contact-form">
      <div className="form-group">
        <label htmlFor="name" className="form-label">
          Name:
        </label>
        <input
          className="form-input"
          type="text"
          name="user_name"
          id="name"
          placeholder="Enter your name"
        />
      </div>

      <div className="form-group">
        <label htmlFor="email" className="form-label">
          Email:
        </label>
        <input
          className="form-input"
          type="email"
          name="user_email"
          id="email"
          placeholder="Enter your email"
        />
      </div>

      <div className="form-group">
        <label htmlFor="query" className="form-label">
          Query:
        </label>
        <textarea
          name="message"
          id="query"
          cols="10"
          rows="5"
          placeholder="Type your Query"
          className="form-input"
        ></textarea>
      </div>

      <div className="form-group">
        <input type="submit" value="Submit" className="form-submit" />
      </div>
    </form>
  );
};

export default ContactForm;
