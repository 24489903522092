import React, { useState } from "react";
import "./SearchForm.styles.css";
import { useNavigate } from "react-router-dom";

const SearchForm = (props) => {
  const { darkTheme } = props;
  const [searchField, setSearchField] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setSearchField(e.target.value);
  }

  const redirectToSearch = () => {
    if(searchField === '') {
      alert("Please enter a book name!")
    }
    else {
      navigate('/search', {state: searchField});
    }
  }

  return (
    <section
      className={`search-form-container ${
        darkTheme ? "box-shadow-light" : "box-shadow-dark"
      }`}
    >
      <input
        type="text"
        name=""
        id=""
        value={searchField}
        onChange={handleChange}
        placeholder="Search books"
        className="search-input"
      />
      <button
      onClick={redirectToSearch}
      className="btn">Search</button>
    </section>
  );
};

export default SearchForm;
