import React from "react";
import "./Showcase.styles.css";
import Navbar from "../navbar/Navbar";
import SearchForm from "../../form/searchForm/SearchForm";

const Showcase = () => {
  return (
    <section className="showcase-container flex justify-center items-center">
      <Navbar darkTheme={false}/>

      <div className="overlay"></div>
      <div className="showcase-content">
        {/* <h1>Best Books Available</h1>
        <p>Buy quality books</p> */}

        <SearchForm darkTheme={false}/>
      </div>
    </section>
  );
};

export default Showcase;
