import React from "react";
import "./Register.styles.css";
import Navbar from "../../components/layouts/navbar/Navbar"
import AuthForm from "../../components/form/authForm/AuthForm";

const Register = () => {
  return (
    <React.Fragment>
      <Navbar darkTheme={true}/>

    <section className="signup-container flex justify-center items-center">
      <div className="content-wrapper">
        <h2>Sign Up</h2>
        <p>Create a new account with email and password.</p>

        <AuthForm btn_title="Register"/>
      </div>
    </section>
    </React.Fragment>
  );
};

export default Register;
