export const BookData = [
    {
        id: 0,
        book_name: 'Vertex English for 6',
        book_description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi officiis cumque illum animi facilis dolores aut dicta ea. Placeat, nisi?',
        book_url: "https://jinnopex.com/images/class-six.jpg",
        print_length: '172 pages',
        author_name: 'Nafizul Muntahar',
        price: '280'
    },
    {
        id: 1,
        book_name: 'Vertex English for 7',
        book_description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi officiis cumque illum animi facilis dolores aut dicta ea. Placeat, nisi?',
        book_url: "https://jinnopex.com/images/class-seven.jpg",
        print_length: '172 pages',
        author_name: 'Nafizul Muntahar',
        price: '280'
    },
    {
        id: 2,
        book_name: 'Vertex English for 8',
        book_description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi officiis cumque illum animi facilis dolores aut dicta ea. Placeat, nisi?',
        book_url: "https://jinnopex.com/images/class-eight.jpg",
        print_length: '172 pages',
        author_name: 'Nafizul Muntahar',
        price: '280'
    },
    {
        id: 3,
        book_name: 'Vertex English for 9',
        book_description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi officiis cumque illum animi facilis dolores aut dicta ea. Placeat, nisi?',
        book_url: "https://jinnopex.com/images/class-nine.jpg",
        print_length: '400 pages',
        author_name: 'Nafizul Muntahar',
        price: '390'
    },
    {
        id: 4,
        book_name: 'Vertex SSC 1st PART',
        book_description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi officiis cumque illum animi facilis dolores aut dicta ea. Placeat, nisi?',
        book_url: "https://jinnopex.com/images/ssc-first.jpg",
        print_length: '172 pages',
        author_name: 'Nafizul Muntahar',
        price: '280'
    },
    {
        id: 5,
        book_name: 'Vertex SSC 2nd PART',
        book_description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi officiis cumque illum animi facilis dolores aut dicta ea. Placeat, nisi?',
        book_url: "https://jinnopex.com/images/ssc-second.jpg",
        print_length: '172 pages',
        author_name: 'Nafizul Muntahar',
        price: '280'
    },
    {
        id: 6,
        book_name: 'Vertex HSC 1st PART',
        book_description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi officiis cumque illum animi facilis dolores aut dicta ea. Placeat, nisi?',
        book_url: "https://jinnopex.com/images/hsc-second.jpg",
        print_length: '172 pages',
        author_name: 'Nafizul Muntahar',
        price: '280'
    },
    {
        id: 7,
        book_name: 'Vertex HSC 2nd PART',
        book_description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi officiis cumque illum animi facilis dolores aut dicta ea. Placeat, nisi?',
        book_url: "https://jinnopex.com/images/hsc-second.jpg",
        print_length: '172 pages',
        author_name: 'Nafizul Muntahar',
        price: '280'
    }
]