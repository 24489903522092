import React from "react";
import "./Books.styles.css";
import Navbar from "../../components/layouts/navbar/Navbar";
import SearchForm from "../../components/form/searchForm/SearchForm";
import AllProducts from "../../components/layouts/allProducts/AllProducts";
import Footer from "../../components/layouts/footer/Footer";

const Books = () => {
  return (
    <section>
      <Navbar darkTheme={true} />

      <div className="search-container">
        <h2>Find the books that you want</h2>
        <SearchForm darkTheme={true} />
      </div>

      <AllProducts />
      <Footer />
    </section>
  );
};

export default Books;
