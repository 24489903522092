import React from "react";
import "./Footer.styles.css";
import ContactForm from "../../form/contactForm/ContactForm";

const Footer = () => {
  return (
    <section className="footer-container">
      <div className="container">
        <h2>If you have any queries feel free to ask here.</h2>

        <ContactForm />
        <p className="copyright">Copyright &copy; 2021 <span>Scholar's Pathshala</span> | All Rights Reserved</p>
      </div>
    </section>
  );
};

export default Footer;
