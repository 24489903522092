import React from "react";
import "./Cart.styles.css";
import Navbar from "../../components/layouts/navbar/Navbar";
import Footer from "../../components/layouts/footer/Footer";
import CartItemsContainer from "../../components/layouts/cartItemsContainer/CartItemsContainer";

const Cart = () => {
  return (
    <section>
      <Navbar darkTheme={true} />
      <CartItemsContainer />
      <Footer />
    </section>
  );
};

export default Cart;
