import React from 'react'
import Showcase from '../../components/layouts/showcase/Showcase'
import ProductList from '../../components/layouts/productList/ProductList'
import Footer from '../../components/layouts/footer/Footer'

const Home = () => {
  return (
    <section>
      <Showcase />
      <ProductList />
      <Footer />
    </section>
  )
}

export default Home
