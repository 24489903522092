import React from 'react'
import ContactForm from '../../components/form/contactForm/ContactForm'
import './Contact.styles.css'

const Contact = () => {
  return (
    <ContactForm />
  )
}

export default Contact
