import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../components/layouts/navbar/Navbar";
import Footer from "../../components/layouts/footer/Footer";
import "./Search.styles.css";
import { BookData } from "../../util/BookData";
import SearchResultCard from "../../components/cards/searchResultCard/SearchResultCard"

const Search = () => {
  const location = useLocation();
  const [ searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    let searchValue = [];

    searchValue = BookData.filter((data) =>
      data.book_name.toLowerCase().includes(location.state.toLowerCase())
    );
    
    setSearchResult(searchValue);
  }, []);

  return (
    <section>
      <Navbar darkTheme={true} />

      <div className="search-result-container">
        <div className="container">
          <h2>Your Search Result</h2>

          <div className="list-container flex wrap justify-between items-center">
          {searchResult.map((result) => (
            <SearchResultCard bookData={result} />
          ))}
          </div>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default Search;
