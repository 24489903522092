import React, { useState, useEffect, useContext } from "react";
import "./Details.styles.css";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { BookData } from "../../../util/BookData";
import { UserContext, CartContext } from "../../../App";

const Details = () => {
  const { id } = useParams();
  const [bookData, setBookData] = useState({});

  const user = useContext(UserContext);
  const { cartItems, setCartItems } = useContext(CartContext);
  const navigate = useNavigate();

  useEffect(() => {
    let newData = BookData.filter((book) => book.id === parseInt(id));
    setBookData(newData[0]);
  }, []);

  const handleAddToCart = () => {
    if(user) {
      setCartItems([...cartItems, bookData])
      alert(`${bookData.book_name} added to cart!`)
    }
    else {
      navigate('/login')
      alert("Please Login or Register first")
    }
  }

  return (
    <section className="details-container">
      <div className="container">
        <div className="book-details flex justify-between items-center">
          <div className="book-img-container">
            <img src={bookData.book_url} alt="" />
          </div>

          <div className="book-details-container">
            <h2>{bookData.book_name}</h2>
            <h4>{bookData.author_name}</h4>
            <p className="book-description">{bookData.book_description}</p>

            <p>Book Length: {bookData.print_length} pages</p>
            <h3>&#2547;{bookData.price}</h3>
            <Link
            onClick={handleAddToCart}
            className="cart-btn">
              Add to Card
            </Link>

            {/* <Link to="/checkout" className="cart-btn">Order Book</Link> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Details;
