import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAQiTp_qUsPWUI2EywxTKl0NGEQcY9W_bY",
  authDomain: "vertex-38f8a.firebaseapp.com",
  projectId: "vertex-38f8a",
  storageBucket: "vertex-38f8a.appspot.com",
  messagingSenderId: "523875795279",
  appId: "1:523875795279:web:1985279850d19ab342c53a",
  measurementId: "G-0N5408YYX4"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;