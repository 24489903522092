import React, { useState, useEffect, createContext } from "react";
import { Routes, Route } from "react-router-dom";
import app from "./firebase/Firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Home from "./pages/home/Home";
import Books from "./pages/books/Books";
import Contact from "./pages/contact/Contact";
import Cart from "./pages/cart/Cart";
import BookDetails from "./pages/bookDetails/BookDetails";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import ScrollToTop from "./components/util/ScrollToTop";
import Search from "./pages/Search/Search";

export const UserContext = createContext({});
export const CartContext = createContext({});

const App = () => {
  const auth = getAuth(app);
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticatedUser(user);
      } else {
        setAuthenticatedUser(null);
      }
    });
  }, []);

  useEffect(() => {
    let total = 0;
    cartItems.forEach((item) => {
      total = total + parseInt(item.price);
    });

    setTotalAmount(total);
  }, [cartItems]);

  return (
    <ScrollToTop>
      <UserContext.Provider value={authenticatedUser}>
        <CartContext.Provider value={{ cartItems, totalAmount, setCartItems }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/books" element={<Books />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/search" element={<Search />} />
            <Route path="/book-details/:id" element={<BookDetails />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Routes>
        </CartContext.Provider>
      </UserContext.Provider>
    </ScrollToTop>
  );
};

export default App;
