import React from "react";
import "./AllProducts.styles.css";
import ProductCart from "../../cards/productCard/ProductCard";
import { BookData } from "../../../util/BookData";

const AllProducts = () => {
  return (
    <section className="all-product-container">
      <div className="container">
        <div className="grid-container">
          {BookData.map((book) => {
            return (
              <div className="grid-item">
                <ProductCart key={book.id} bookData={book} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default AllProducts;
