import React, { useContext } from "react";
import "./Navbar.styles.css";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../App";
import { FaShoppingCart } from "react-icons/fa";
import { getAuth, signOut } from "firebase/auth";
import app from "../../../firebase/Firebase";
import imgLogo from "../../../assets/logo.png";

const Navbar = (props) => {
  const { darkTheme } = props;

  const user = useContext(UserContext);
  const auth = getAuth(app);
  const navigate = useNavigate();

  const handleLogOut = () => {
    signOut(auth).then(() => {
      navigate("/");
    })
    .catch((err) => {
      console.log(err);
    })
  };

  const showLoginAndRegister = (
    <nav className="nav-menu">
      <Link to="/" className="nav-items">
        Home
      </Link>
      <Link to="/books" className="nav-items">
        Books
      </Link>
      <Link to="/contact" className="nav-items">
        Contact
      </Link>
      <Link to="/login" className="nav-items">
        Login
      </Link>
      <Link to="/register" className="nav-items">
        Register
      </Link>
    </nav>
  );

  const showLogoutAndCart = (
    <nav className="nav-menu">
      <Link to="/" className="nav-items">
        Home
      </Link>
      <Link to="/books" className="nav-items">
        Books
      </Link>
      <Link to="/contact" className="nav-items">
        Contact
      </Link>
      <Link to="/" onClick={handleLogOut} className="nav-items">
        Logout
      </Link>
      <Link to="/cart">
        <FaShoppingCart
          style={{
            color: "#fff",
            width: "30px",
            display: "inline-block",
            marginLeft: "1rem",
            cursor: "pointer",
            marginBottom: "-3px",
          }}
        />
      </Link>
    </nav>
  );

  return (
    <section
      className={
        darkTheme ? "navbar-container bg-dark relative" : "navbar-container"
      }
    >
      <div className="container flex justify-between items-center">
        <Link to="/" className="logo">
          <img
            src={imgLogo}
            alt=""
            style={{
              width: "140px",
              height: "25px",
              padding: "0 .5rem",
            }}
          />
        </Link>

        {user ? showLogoutAndCart : showLoginAndRegister}

        {/* <nav className="nav-menu">
          <Link to="/" className="nav-items">
            Home
          </Link>
          <Link to="/books" className="nav-items">
            Books
          </Link>
          <Link to="/contact" className="nav-items">
            Contact
          </Link>
        </nav> */}
      </div>
    </section>
  );
};

export default Navbar;
